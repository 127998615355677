export enum NotificationType {
	WARNING = 'warning',
	INFO = 'info',
	SUCCESS = 'success',
	ERROR = 'error',
}

export enum NotificationStatus {
	ARCHIVED = 'archived',
	CREATED = 'created',
	ACTIVE = 'active',
	READ = 'read',
}

export class Notification {
	status!: NotificationStatus;
	type!: NotificationType;
	message!: string;
	action?: string;
	// eslint-disable-next-line camelcase
	created_at?: string;
}
