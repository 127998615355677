import { Site } from '@/thunder/modules/site/site.model';
import { readItem, readItems, type Query } from '@directus/sdk';
import type {
	Schema,
	Sites,
} from '@thunder/core/src/services/baas/directus/schema';

interface State {
	_list: Site[];
	_current?: Site;
}

export const useSiteStore = () => {
	const { $baas } = useNuxtApp();

	return defineStore('site', {
		state: (): State => ({
			_list: [],
			_current: undefined,
		}),
		getters: {
			current: state => state._current,
			list: state => state._list,

			/**
			 *
			 * This getter will attempt to fill relationships from existing records in relationship stores.
			 * Take note that this will reactivate when the relationship stores are updated.
			 * See {@link useSiteStore#fillRelations}
			 */
			get: state => {
				return (id: number): Site | null => {
					// if (invoice) {
					// 	return getRelations(invoice);
					// }

					return state._list?.find(item => item.id === id) ?? null;
				};
			},
		},
		actions: {
			async search(query?: Query<Schema, Sites>) {
				const data = await $baas.request(readItems('sites', query));

				for (const item of data) {
					this.listUpsert(item.id, item);
				}

				return data.map(_site => new Site(_site));
			},

			async fetchMany(query?: Query<Schema, Sites>) {
				const data = await $baas.request(readItems('sites', query));

				for (const item of data) {
					this.listUpsert(item.id, item);
				}

				return this._list;
			},

			async fetch(id: number, query?: Query<Schema, Sites>) {
				const data = await $baas.request(readItem('sites', id, query));

				this.listUpsert(data.id, data);
			},

			listUpsert(id: string | number, data: object) {
				const index = this._list.findIndex(_item => _item.id === id);
				if (index >= 0) {
					this._list[index] = new Site(data);
				} else {
					this._list.push(new Site(data));
				}
			},

			setCurrent(data: Partial<Site>) {
				this._current = new Site(data);
				this.listUpsert(this._current.id, data);
			},
		},
	});
};
