import type { NotificationType } from '@/thunder/modules/ui/types';

interface StateType {
	_alert?: {
		message: string;
		type: NotificationType;
	};
	_toast?: {
		message: string;
		type: NotificationType;
	};
}

export const useUIStore = defineStore('ui', {
	state: (): StateType => ({
		_alert: undefined,
		_toast: undefined,
	}),

	getters: {
		alert: state => state._alert,
		toast: state => state._toast,
		showToast: state => Boolean(state._toast?.message ?? 0),
	},

	actions: {
		clearAlert() {
			this._alert = undefined;
		},

		clearToast() {
			this._toast = undefined;
		},
	},
});
